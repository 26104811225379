import React from "react";
// import "./WordDetail.css";

const WordDetail = ({ word, definition, etymology }) => {
  return (
    <div className="word-detail">
      <h2>{word}</h2>
      {definition && <p className="def">{definition}</p>}
      {etymology && <p className="ety">{etymology}</p>}
    </div>
  );
};

export default WordDetail;
