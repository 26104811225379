import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AddWord from "./AddWord";
import HomePage from "./HomePage"; // Import any other components (like a home page)
import "./App.css";

// Define your components here (like HomePage) or import them from other files

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Define your routes here */}
        <Route path="/" element={<HomePage />} /> {/* Default home route */}
        <Route path="/add" element={<AddWord />} />
      </Routes>
    </Router>
  );
};

export default App;
