import React, { useState, useEffect } from "react";
import axios from "axios";

const AddWord = () => {
  const [word, setWord] = useState("");
  const [definition, setDefinition] = useState("");
  const [etymology, setEtymology] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    // Check if user is already authenticated via localStorage
    const savedPassword = localStorage.getItem("password");
    if (savedPassword === "letmein") {
      setAuthenticated(true);
    }
  }, []);

  // Function to handle password submission and save it in localStorage
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === "letmein") {
      localStorage.setItem("password", password);
      setAuthenticated(true);
    } else {
      setMessage("Incorrect password.");
    }
  };

  // Function to fetch word data via the Netlify serverless function
  const fetchWordData = async () => {
    try {
      const response = await axios.get(
        `/.netlify/functions/scrapeWord?word=${word}`
      );
      const data = response.data;

      setDefinition(data.definition || "No definition found");
      setEtymology(data.etymology || "No etymology found");
    } catch (error) {
      console.error("Error fetching word data:", error);
      setMessage("Failed to fetch word data.");
    }
  };

  // Function to handle word submission (append to the JSON file)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/.netlify/functions/addWord`, {
        word,
        definition,
        etymology,
        password: "letmein",
      });

      if (response.status === 200) {
        setMessage("Word added successfully!");
      } else {
        setMessage("Failed to add word.");
      }
    } catch (error) {
      console.error("Error submitting word:", error);
      setMessage(
        "Error: " + error.response?.data?.error || "Error submitting word."
      );
    }
  };

  // Render the password form if not authenticated
  if (!authenticated) {
    return (
      <div>
        <h1>Enter Password</h1>
        <form onSubmit={handlePasswordSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Submit</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    );
  }

  // Render the word lookup form if authenticated
  return (
    <div>
      <h1>Add a New Word</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Word:</label>
          <input
            type="text"
            value={word}
            onChange={(e) => setWord(e.target.value)}
          />
        </div>
        <button type="button" onClick={fetchWordData}>
          Look Up Word
        </button>

        {definition && (
          <div>
            <h3>Definition:</h3>
            <p>{definition}</p>
          </div>
        )}

        {etymology && (
          <div>
            <h3>Etymology:</h3>
            <p>{etymology}</p>
          </div>
        )}

        <button type="submit">Submit</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default AddWord;
