import React, { useEffect, useState } from "react";
import WordDetail from "./WordDetail";
// import "./HomePage.css";

// Fisher-Yates (Knuth) Shuffle Algorithm
const shuffleArray = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const HomePage = () => {
  const [words, setWords] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchWords = async () => {
      setLoading(true);
      try {
        const response = await fetch("/words.json");
        const data = await response.json();
        const newWords = data.words.slice((page - 1) * 5, page * 5); // Pagination: Fetch 5 words at a time
        const shuffledWords = shuffleArray([...newWords]); // Shuffle the words array
        setWords((prevWords) => [...prevWords, ...shuffledWords]); // Append shuffled words
      } catch (error) {
        console.error("Error fetching the words:", error);
      }
      setLoading(false);
    };

    fetchWords();
  }, [page]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="homepage-container">
      {words.map((wordData, index) => (
        <div key={index} className="word-post">
          <WordDetail
            word={wordData.word}
            definition={wordData.definition}
            etymology={wordData.etymology}
          />
        </div>
      ))}
      {loading && <p>Loading more words...</p>}
    </div>
  );
};

export default HomePage;
